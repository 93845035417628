import { env } from '@/config';
import { NewslettersStrings as strings } from '@/translations';

import { Newsletter } from '@/types/Newsletters';

import { DataId } from '@/utils/nitrous/constants';

import HotRodImage from '@/assets/images/newsletters/hotrod.jpg';
import MotorTrendImage from '@/assets/images/newsletters/motortrend.jpg';
import NoBoringCarsImage from '@/assets/images/newsletters/no-boring-cars.jpg';
import TrucksOffroadImage from '@/assets/images/newsletters/truck-offroad.jpg';

import { Routes } from './routes';

const newsletters: Newsletter[] = [
  {
    dataId: DataId.NewslettersAddMotorTrend,
    description: strings.NewsletterDescription.MotorTrend,
    id: 23,
    image: MotorTrendImage,
    name: 'motortrend_newsletter_subscription',
    title: 'MotorTrend',
  },
  {
    dataId: DataId.NewslettersAddHotRod,
    description: strings.NewsletterDescription.HotRod,
    id: 3498,
    image: HotRodImage,
    name: 'hotrod_newsletter_subscription',
    title: 'Hot Rod',
  },
  {
    dataId: DataId.NewslettersAddTrucksOffroad,
    description: strings.NewsletterDescription.TrucksOffroad,
    id: 3529,
    image: TrucksOffroadImage,
    name: 'truckoffroad_newsletter_subscription',
    title: 'Trucks & Off Road',
  },
  {
    dataId: DataId.NewslettersAddNoBoringCars,
    description: strings.NewsletterDescription.NoBoringCars,
    id: 3528,
    image: NoBoringCarsImage,
    name: 'noboringcars_newsletter_subscription',
    title: '#No Boring Cars',
  },
];

const SeoData = {
  canonical: `${env.baseUrl}${Routes.Newsletters}`,
  title: `MotorTrend Group Newsletters: MotorTrend, HotRod, Trucktrend, Fourwheeler, Automobile Magazine`,
  description: `Trust MotorTrend for the best new car reviews and tests, the latest auto news. HOT ROD has advocated for customizing, racing, and wrenching. Truck and Off-Road showcases the newest trucks to the wildest rigs.`,
  keywords: [
    'motortrend newsletter',
    'hotrod newsletter',
    'fourwheeler newsletter',
    'trucktrend newsletter',
    'automobilemag newsletter',
  ],
  image: {
    url: 'https://st.motortrend.com/app/themes/motortrend/mantle/modules/assets/motortrend-logo-share.png',
    width: 1080,
    height: 565,
  },
};

export const NewslettersConstants = {
  SeoData,
  newsletters,
};
